import React from 'react'
import { useTranslation } from 'next-i18next'
import styles from './NativePermissions.module.scss'

type MyProps = {
  closePopups: (close: boolean) => void
  nextPopup: (permissionName: string, page: number) => void
}

const TrackingPopup = ({ closePopups, nextPopup }: MyProps) => {
  const closePopupHandler = (event: React.MouseEvent) => {
    const modal = document.getElementById('background')
    if (event.target === modal) {
      closePopups(false)
    }
  }
  const { t }: { t: any } = useTranslation()

  return (
    <div id="background" className={styles.root} onClick={closePopupHandler}>
      <div className={styles.banner_mobile}>
        <div className={styles.banner_mobile__container}>
          <h2
            className={`${styles.banner_mobile__header} ${styles.banner_mobile__header_size}`}
          >
            {t('allow-tracking-on')}
          </h2>
          <ul className={styles.banner_mobile__list}>
            <li className={styles.banner_mobile__item}>
              <img
                className={styles.banner_mobile__image}
                src="/images/banner_pic1.svg"
                alt="banner"
              />
              <p
                className={`${styles.banner_mobile__text} ${styles.banner_mobile__text_align}`}
              >
                {t('learn-about-your-preferences-part-1')}{' '}
                <span className={styles.span__weight}>
                  {t('learn-about-your-preferences-part-2')}
                </span>
              </p>
            </li>
            <li className={styles.banner_mobile__item}>
              <img
                className={styles.banner_mobile__image}
                src="/images/banner_pic2.svg"
                alt="banner"
              />
              <p
                className={`${styles.banner_mobile__text} ${styles.banner_mobile__text_align}`}
              >
                {t('provide-you-with-part-1')}{' '}
                <span className={styles.span__weight}>
                  {t('provide-you-with-part-2')}
                </span>
                {t('provide-you-with-part-3')}
              </p>
            </li>
            <li className={styles.banner_mobile__item}>
              <img
                className={styles.banner_mobile__image}
                src="/images/banner_pic3.svg"
                alt="banner"
              />
              <p
                className={`${styles.banner_mobile__text} ${styles.banner_mobile__text_align}`}
              >
                {t('only-show-you-advertisements-part-1')}{' '}
                <span className={styles.span__weight}>
                  {t('only-show-you-advertisements-part-2')}
                </span>
              </p>
            </li>
          </ul>

          <p
            className={`${styles.banner_mobile__text} ${styles.banner_mobile__text_size}`}
          >
            {t('you-can-change-this')}
          </p>
          <button
            className={styles.banner_mobile__button}
            onClick={() => nextPopup('tracking', 1)}
          >
            {t('continue')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default TrackingPopup
