export const menuMobileData = [
    {
        id: 0,
        title: 'home',
        src: '/images/MobileMenu/1.svg',
        srcActive: '/images/MobileMenu/1active.svg',
        link: '/',
        currentPage: 'index',
        idLink: "homemenu",
    },
    {
        id: 1,
        title: 'shop',
        src: '/images/MobileMenu/2.svg',
        srcActive: '/images/MobileMenu/2active.svg',
        link: '/shop',
        currentPage: 'shop',
        idLink: "shopmenu"
    },
    {
        id: 2,
        title: 'cart',
        src: '/images/MobileMenu/3.svg',
        srcActive: '/images/MobileMenu/3active.svg',
        link: '/cart',
        currentPage: 'cart',
        idLink: "cartmenu"
    },
    {
        id: 3,
        title: 'my_account',
        src: '/images/MobileMenu/4.svg',
        srcActive: '/images/MobileMenu/4active.svg',
        link: '/account',
        currentPage: 'account',
        idLink: "accountmenu"
    },
]
