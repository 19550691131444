import React, { useEffect, useState } from 'react'
import styles from './MobileMenu.module.scss'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { menuMobileData } from './staticData'
import cn from 'classnames'
import { checkCookies, getCookie } from 'cookies-next'
import { shopifyCartCookie } from '../../../constants/constants'
import { parseJson } from '../../../utilities/jsonUtils'

const MobileMenu = ({ currentPage }) => {
  const { t } = useTranslation('header')
  const [count, setCount] = useState(0)

  let _shopifyCookie = getCookie(shopifyCartCookie)

  _shopifyCookie = _shopifyCookie ? parseJson(_shopifyCookie) : null

  useEffect(() => {
    setCount(_shopifyCookie?.countOfProducts || 0)
  }, [_shopifyCookie, checkCookies(shopifyCartCookie)])

  return (
    <section className={styles.container}>
      <ul className={styles.list}>
        {menuMobileData.map((item) => {
          return (
            <li
              key={item.id}
              className={
                currentPage === item.currentPage
                  ? cn(styles.activeItem, styles.item)
                  : styles.item
              }
            >
              <a href={item.link} id={item.idLink}>
                {count && item.title === 'cart' ? (
                  <span className={styles.count}>{count}</span>
                ) : (
                  <span className={styles.countNot}></span>
                )}
                <Image
                  src={
                    currentPage === item.currentPage ? item.srcActive : item.src
                  }
                  alt="icon"
                  width={24}
                  height={24}
                  objectFit="contain"
                />
                <p
                  className={
                    currentPage === item.currentPage
                      ? cn(styles.text, styles.textActive)
                      : styles.text
                  }
                >
                  {t(item.title)}
                </p>
              </a>
            </li>
          )
        })}
      </ul>
    </section>
  )
}

export default MobileMenu
