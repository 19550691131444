import React from 'react'
import { useTranslation } from 'next-i18next'
import styles from './NativePermissions.module.scss'

type MyProps = {
  closePopups: (close: boolean) => void
  nextPopup: (permissionName: string, page?: number) => void
}

const StartUsagePopup = ({ closePopups, nextPopup }: MyProps) => {
  const closePopupHandler = (event: React.MouseEvent) => {
    const modal = document.getElementById('background')
    if (event.target === modal) {
      closePopups(false)
    }
  }
  const { t }: { t: any } = useTranslation()
  return (
    <div id="background" className={styles.root} onClick={closePopupHandler}>
      <div className={styles.banner_mobile}>
        <div className={styles.banner_mobile__container}>
          <h2 className={styles.banner_mobile__header}>
            {t('pay-for-supplements-not-for-marketing')}
          </h2>
          <p className={styles.banner_mobile__text}>
            {t('40-off-on-your-food')}
          </p>
          <img
            className={styles.banner_mobile__image}
            src="/images/honest_prices_table.png"
            alt="UNLOCK HONEST PRICES"
          />
          <h3 className={styles.banner_mobile__title}>
            {t('take-advantage-part-1')}
            <br />
            {t('take-advantage-part-2')}
          </h3>
          <p className={styles.banner_mobile__text}>
            {t('try-a-1-month-membership-for-free-no-hidden-fees')}
          </p>
          <button
            className={styles.banner_mobile__button}
            type="button"
            onClick={() => nextPopup('redirect')}
          >
            {t('start-my-trial-month')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default StartUsagePopup
