import React from 'react'
import { useTranslation } from 'next-i18next'
import styles from './NativePermissions.module.scss'

type MyProps = {
  closePopups: (close: boolean) => void
  nextPopup: (permissionName: string, page: number) => void
}
const NotificationPopup = ({ closePopups, nextPopup }: MyProps) => {
  const closePopupHandler = (event: React.MouseEvent) => {
    const modal = document.getElementById('background')
    if (event.target === modal) {
      closePopups(false)
    }
  }
  const { t }: { t: any } = useTranslation()
  return (
    <div id="background" className={styles.root} onClick={closePopupHandler}>
      <div className={styles.banner_mobile}>
        <div className={styles.banner_mobile__container}>
          <h2
            className={`${styles.banner_mobile__header} ${styles.banner_mobile__header_size}`}
          >
            {t('enable-notifications')}
          </h2>
          <p
            className={`${styles.banner_mobile__text} ${styles.banner_mobile__text_size}`}
          >
            {t('receive-timely-notifications')}
          </p>
          <ul className={styles.banner_mobile__list3}>
            <li className={styles.banner_mobile__item3}>
              <img
                className={styles.banner_mobile__img}
                src="/images/vitaClub.svg"
                alt="vitaClub"
              />
              <div className={styles.banner_mobile__wrap}>
                <h3 className={styles.banner_mobile__title3}>
                  {t('order-status')}
                </h3>
                <p className={styles.banner_mobile__text3}>
                  {t('you-will-get-a-notification-about-your-order-status')}
                </p>
              </div>
              <p className={styles.banner_mobile__now}>{t('now')}</p>
            </li>
            <li className={styles.banner_mobile__item3}>
              <img
                className={styles.banner_mobile__img}
                src="/images/vitaClub.svg"
                alt="vitaClub"
              />
              <div className={styles.banner_mobile__wrap}>
                <h3 className={styles.banner_mobile__title3}>
                  {t('personalized-offers')}
                </h3>
                <p className={styles.banner_mobile__text3}>
                  {t('well-notify-when-you-recieve-personal-discounts')}
                </p>
              </div>
              <p className={styles.banner_mobile__now}>{t('now')}</p>
            </li>
          </ul>

          <p
            className={`${styles.banner_mobile__text} ${styles.banner_mobile__text_size} ${styles.banner_mobile__text_new}`}
          >
            <b>{t('select-allow-on-the-next-screen')}</b>
          </p>
          <button
            className={`${styles.banner_mobile__button} ${styles.banner_mobile__button2}`}
            onClick={() => nextPopup('notification', 2)}
          >
            {t('continue')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default NotificationPopup
